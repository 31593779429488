.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Tradingview */
#tradingview_widget_wrapper > *:last-child {
    display: none;
}

#tradingview_widget_wrapper {
    width: 100%;
}

:root {
    --tweet-body-font-size: 1rem !important;
}

.react-tweet-theme {
    --tweet-body-font-size: 0.9rem !important;
    --tweet-body-font-weight: 500 !important;
    --tweet-body-line-height: 1.0rem !important;
    --tweet-info-font-size: 0.8rem !important;
    --tweet-info-line-height: 1.0rem !important;
}

/*
#root div[class^='tweet-header_authorFollow'] {
    display: none !important;
}

#root div[class^='tweet-actions_actions'] {
    display: none !important;
}

#root div[class^='tweet-replies_replies'] {
    display: none !important;
}
*/
#root div[class*='tweet-container_root'] {
    border-radius: 0px !important;
}
